<template >
  <div v-if="show">
    <block-title>退貨紀錄</block-title>

    <v-container v-if="showApplyBack && auth" class="mb-5">
      <v-row>
        <custom-form-action-btn :defaultKey="defaultKey" :action="{
          key: 'click',
          handler: goOrderBack,
        }" label="申請退貨" color="secondary" class="btn-min-width" :cols="{ cols: 4 }"></custom-form-action-btn>
      </v-row>
    </v-container>

    <template v-if="showBackOrder">
      <v-row>
        <!-- <custom-form-action-btn
        :defaultKey="defaultKey"
        :action="{
          key: 'click',
          dynamicAttr: {
            name: 'status',
            value: orderConstants.STATUS_REQUEST_RETURN,
          },
        }"
        label="查看明細"
        :cols="{ cols: 4 }"
      ></custom-form-action-btn> -->

        <v-col class="d-flex align-center">
          <span>{{ "back-order.status" | t }}: {{ backOrderStatusText }}</span>
        </v-col>
        <v-col cols="4">
          <custom-form-action-btn :defaultKey="defaultKey" :action="{
            key: 'click',
            handler: goOrderBackDetail,
          }" label="查看明細" color="secondary" class="btn-min-width" :cols="{ cols: 4 }"></custom-form-action-btn>
        </v-col>
      </v-row>

      <template v-if="backOrderStatus != 'REJECT'">
        <order-back-card v-for="(item, index) in computedData" :key="item.item_id" v-model="computedData[index]"
          :configs="cardConfigs(index)"></order-back-card>

        <bar v-if="show退款合計" class="" type="primary">
          <template v-slot:label> 退款合計: </template>
          <template v-slot:value>{{ amount }} 元</template>
        </bar>

        <bar v-if="showTotal" class="mt-10" type="secondary">
          <template v-slot:label> 總計: </template>
          <template v-slot:value>{{ total }} 元</template>
        </bar>
      </template>
    </template>
    <template v-else>
      <p class="text--disabled">無退貨紀錄</p>
    </template>
  </div>
</template>

<script lang="babel" type="text/babel">
import BackRecords from "@/mixins/BackRecords.js"

export default {
  mixins: [BackRecords],
  props: {
    auth: {
      type: Boolean,
    },
  },
  computed: {
    showApplyBack() {
      if (['sended', 'finished'].includes(this.status) && this.propsValue.applyPayId == 0) {
        if (!this.backItems) return true
      }
      return false
    },
    // 購買商品合計 - 退款總計
    total() {
      return Math.round(this.total_amounts - this.amount)
    },
  },
  methods: {
    cardConfigs(index) {
      const item = this.data[index]

      const config = [
        { label: item.product, colon: false, labelColor: "primary--text" },
        {
          id: "return_count",
          label: "退貨數量",
          col: 6,
        },
        {
          id: "price",
          valueSuffix: "元",
          col: 6
        },
      ]
      if(this.show退款小計) {
        config.push(
            {
              id: "total",
              label: "退款小計",
              type: "total",
              barType: 'gray',
              valueSuffix: "元",
              value: item.amount
            },
        )
      }
      return config
    },
    goOrderBack() {
      this.$router.push({
        name: 'store-apply-order',
        params: {
          providerId: this.providerId,
          orderId: this.orderId,
        },
      })
    },
    goOrderBackDetail() {
      this.$router.push({
        name: 'store-back-order-detail',
        params: {
          providerId: this.providerId,
          orderId: this.orderId,
        },
      })
    },
  },
}
</script>

